import React, { useContext } from "react";
import { SlotMachineContext } from "../../contexts/SlotMachine.context";

function Loader() {
  const { loading } = useContext(SlotMachineContext);

  return (
    loading && (
      <div className="modal d-flex" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
        <div className="modal-dialog d-flex w-100">
          <div className="modal-content w-75 m-auto p-4 text-center" style={{ opacity: 1 }}>
            <i className="fas fa-spinner fa-spin text-secondary" style={{ fontSize: 50 }}></i>
          </div>
        </div>
      </div>
    )
  );
}

export default Loader;
