import React, { useEffect, useContext } from "react";
import { ethers } from "ethers";
import { SlotMachineContext } from "../../contexts/SlotMachine.context";
// import MachineImg from "../../assets/machine.png";
import "./index.css";
import $ from "jquery";
import { useTranslation } from "react-i18next";

const Machine = ({ reward, rollStarted, rollsFetched, winType, tokenPrice }) => {
  const { selectedToken } = useContext(SlotMachineContext);
  const { t, i18n } = useTranslation("main_page");

  useEffect(() => {
    var body = $("#starshine"),
      template = $(".template.shine"),
      stars = 40,
      sparkle = 20;

    var size = "small";
    var createStar = function () {
      template
        .clone()
        .removeAttr("id")
        .css({
          top: Math.random() * 100 + "%",
          left: Math.random() * 100 + "%",
          webkitAnimationDelay: Math.random() * sparkle + "s",
          mozAnimationDelay: Math.random() * sparkle + "s",
        })
        .addClass(size)
        .appendTo(body);
    };

    for (var i = 0; i < stars; i++) {
      if (i % 2 === 0) {
        size = "small";
      } else if (i % 3 === 0) {
        size = "medium";
      } else {
        size = "large";
      }

      createStar();
    }
  }, []);

  const showStars = !rollStarted && rollsFetched && winType !== "";

  return (
    <div className="casino__machine">
      {/* <img className="machine-img" src={MachineImg} /> */}
      <div className="doors">
        <div id="starshine" style={{ display: `${showStars ? "block" : "none"}` }}>
          <div className="template shine"></div>
        </div>
        <div className="winning">
          {!rollStarted && rollsFetched && winType !== ""
            ? `${t("you_win")} ${reward} ${selectedToken?.symbol}`
            : !rollStarted && rollsFetched && winType === ""
            ? `${t("you_lose")}`
            : `${t("spin_to_win")} ${ethers.utils.formatEther(String(tokenPrice))} ${selectedToken?.symbol}`}
        </div>
        <div id="door1" className="door">
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
          <div className="box box6"></div>
          <div className="box box7"></div>
          <div className="box box8"></div>
          <div className="box box9"></div>
          <div className="box box10"></div>
          <div className="box box11"></div>
          <div className="box box12"></div>
          <div className="box box13"></div>
          <div className="box box14"></div>
          <div className="box box15"></div>
        </div>

        <div id="door2" className="door">
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
          <div className="box box6"></div>
          <div className="box box7"></div>
          <div className="box box8"></div>
          <div className="box box9"></div>
          <div className="box box10"></div>
          <div className="box box11"></div>
          <div className="box box12"></div>
          <div className="box box13"></div>
          <div className="box box14"></div>
          <div className="box box15"></div>
        </div>

        <div id="door3" className="door">
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
          <div className="box box6"></div>
          <div className="box box7"></div>
          <div className="box box8"></div>
          <div className="box box9"></div>
          <div className="box box10"></div>
          <div className="box box11"></div>
          <div className="box box12"></div>
          <div className="box box13"></div>
          <div className="box box14"></div>
          <div className="box box15"></div>
        </div>
      </div>
    </div>
  );
};

export default Machine;
