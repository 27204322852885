import React, { useContext } from 'react'
import "./index.css";
import Modal from '../../components/Modal';
import WalletImage from '../../assets/wallet.png';
import { Web3Context } from "../../contexts/Web3.context";
import { getSlicedWalletAddress } from '../../utils/helpers';

const successModalStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: "0px",
      color:"white",
      backgroundColor: "#0D0D0D",
      border: "1px solid #272727",
      borderRadius: "20px",
      padding:"10px"
  },
};

function ConnectWalletModalBody() {

  return (
      <div style={{display:"flex", flexDirection:"column"}}>
          <div style={{display:"flex", borderBottom: "1px solid #272727", padding:"0.5em"}}>
              Connect Wallet
          </div>
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"1em"}}>
              <div style={{width:"200px",height:"200px"}}>
                  <img style={{width:"100%",height:"100%",objectFit:"cover"}} src={WalletImage}/>
              </div>
              <span>Great!</span>
              <p className='success_body_p' style={{maxWidth:"55%"}}>
              In order to proceed, you have to connect Metamask wallet.
                  Lorem ipsum dolor set amit.
              </p>
              <button style={{width:"70%",marginBottom:"1em"}} className="btn btn-primary join_tournament_btn joinMatchBtn">
                  <span>Connect to Metamask</span>
              </button>
          </div>
      </div>
  )
}

export default function Navbar() {
  const [connectWalletModalIsOpen, setConnectWalletModalIsOpen] = React.useState(false);
  const { accountAddress, network, checkOrAddNetworkChain } = useContext(Web3Context);

  console.log('====================================');
  console.log({ accountAddress, network, checkOrAddNetworkChain });
  console.log('====================================');
  
  const onConnectWallet = () => {
    setConnectWalletModalIsOpen(true);
  }

  return (
    <div className='navbar-container'>
      <div className='_navbar'>
        <span className='white_item' href="/">Main App</span>
        <span className='white_item' href="/#/my_tournaments" >My Tournaments</span>
        <span className='white_item' href="/#/tutorials" >Tutorials</span>
        <span className='white_item' href="/#/referrals" >My Earnings</span>
        <button onClick={onConnectWallet} className='connect_wallet_btn'>
        {accountAddress ? <span>{getSlicedWalletAddress(accountAddress)}</span> : <span>Connect Wallet</span>}
          <i class="fa-solid fa-location-arrow"></i>
        </button>
      </div>
      <Modal setIsOpen={setConnectWalletModalIsOpen} style={successModalStyles} modalIsOpen={connectWalletModalIsOpen} body={< ConnectWalletModalBody />} />
    </div>
  )
}
